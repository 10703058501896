<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_change_livello"
    l-bs-keyboard="false"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modifica livello omologazione</h5>
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectedLivello = null"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-4">
            <Loading
              :active="isLoading"
              :is-full-page="fullPage"
              :z-index="1100"
            />
            <div class="mb-3 bg-secondary py-5 px-4 rounded">
              <span class="blueFit" v-if="denominazioneSelected">
                Omologazione impianto: {{ denominazioneSelected }}<br
              /></span>
              <span class="blueFit"> Livello attuale: {{ livello }}</span>
            </div>
            <div class="row pt-3">
              <div class="col ps-4">Selezionare il livello:</div>
              <div class="col">
                <SelectInput
                  placeholder="Livello"
                  :options="omologazioni_livelli_impianti"
                  :value="selectedLivello"
                  @changeSelect="setLivello($event)"
                  name="selectedLivello"
                />
              </div>
              <div class="col">
                <button
                  v-if="selectedLivello"
                  type="button"
                  class="badge bg-light-success text-success rounded fs-6"
                  @click="changeLivello(selectedLivello)"
                >
                  Continua
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalLivello"
            @click="selectedLivello = null"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, getCurrentInstance } from "vue";

import Loading from "vue3-loading-overlay";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { changeLivelloOmologazione } from "@/requests/impiantiOmologazione";

export default {
  props: {
    id_Omologazione: {
      type: Number,
    },
    livello: {
      type: Number,
    },
    denominazioneSelected: {
      type: String,
    },
  },
  emit: ["getList"],
  components: { Loading, SelectInput },
  setup(props, { emit }) {
    const isLoading = ref(false);

    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const omologazioni_livelli_impianti = computed(() =>
      store.getters.getStateFromName("omologazioni_livelli_impianti")
    );

    const keys = ref("oli");
    if (!omologazioni_livelli_impianti.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const selectedLivello = ref(null);
    const setLivello = (value) => {
      selectedLivello.value = value;
    };

    changeLivelloOmologazione;

    const changeLivello = () => {
      Swal.fire({
        title: "Attenzione!",
        html: "Cambiando livello verranno effettuati uno storno ed una riemissione del movimento economico basata sul nuovo livello selezionato.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await changeLivelloOmologazione(
            props.id_Omologazione,
            selectedLivello.value
          ).then((res) => {
            if (res.status == 200) {
              alertSuccess("Operazione effettuata correttamente.");
              emit("getList");
              document.getElementById("closeModalLivello").click();
              selectedLivello.value = null;
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    return {
      isLoading,
      omologazioni_livelli_impianti,
      selectedLivello,
      changeLivello,
      setLivello,
    };
  },
};
</script>

<style></style>
